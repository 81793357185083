// BASE [ VARIABLES ] #################################################################################################

// A. MEDIA QUERY BREAKPOINTS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// A.1. BREAKPOINTS ---------------------------------------

// from 575px down
$xs-dn: 'max-width:575px';

// from 576px up
$sm-up: 'min-width:576px';

// from 767px down
$md-dn: 'max-width:767px';

// from 768px up
$md-up: 'min-width:768px';

// from 991px down
$lg-dn: 'max-width:991px';

// from 992px up
$lg-up: 'min-width:992px';

// from 1199px down
$xl-dn: 'max-width:1199px';

// from 1200px up
$xl-up: 'min-width:1200px';

// from 1399px down
$xxl-dn: 'min-width:1399px';

// from 1400px up
$xxl-up: 'min-width:1400px';

// from 1919px down
$hd-dn: 'min-width:1919px';

// from 1920px up
$hd-up: 'min-width:1920px';

//To target specific devices
$tablet: 'max-width:768px';
// A.1. END -----------------------------------------------

// A. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B. SIZES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// B.1. PADDING & MARGINS ---------------------------------

$base-xl: 60px;
$base-lg: 45px;
$base-md: 30px;
$base: 15px;
$base-sm: 8px;
$base-xs: 4px;

// B.1. END -----------------------------------------------

// B. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// C. COLORS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

$primary: #1C4B9D;
$primLight: #0086FF;
$secondary: #262626;
$blueLight: #CDEDFF;
$grey: #262626;
$greyDark: #1a1a1a;
$black: #000000;
$white: #ffffff;
$white-03: rgba(244, 247, 251, 0.3);

// C.2. SITE COLOR CONFIG ---------------------------------

$grey60:    #394146;

$gradientBlue: linear-gradient(100.52deg, #ade1ff 8.1%, #29b2ff 95.08%);
$gradientBlueDark: linear-gradient(247.15deg, #0061a1 35.18%, #29b2ff 93.33%);
$gradientPink: linear-gradient(98.78deg, #eec9ff 0%, #ad6bdf 100%);
$gradientGreen: linear-gradient( 98.78deg,rgba(194, 255, 248, 0.6) 0%,rgba(0, 228, 201, 0.6) 100%);

$brown-70: rgba(#521800, 0.7);

// C.2. END -----------------------------------------------

// C.3. FONT TYPES ----------------------------------------

$fnt-lt:    Nunito-Light, sans-serif;
$font:      Nunito-Regular, sans-serif;
$fnt-b:     Nunito-Bold, sans-serif; 
$fnt-sb:    Nunito-SemiBold, sans-serif;
$fnt-xb:    Nunito-ExtraBold, sans-serif;

// C.3. END -----------------------------------------------

// C. END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33%!important;
    }

}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
