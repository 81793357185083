@use '_module-index' as *;
// COMPONENT [ NAVBAR ] ###############################################################################################

// A.1. COMPONENT STYLE -----------------------------------

.oSubNav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: none;
    &.menuShow {
        display: block;
    }
}

.oSubNav {
    padding-top: 8px;
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
    .oSubNav {
        padding-top: 8px;
        margin-left: 15px;
    }
}
@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
    .oSubNav {
        position: absolute;
        right: 0;
        padding: 0 25px;
        background-color: white;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 8px 8px;
    }
    .sideDropdown {
        left: -100%;
        width: 160px;
        top: 0;
    }
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
