// ATOMS [ MISC ]  ####################################################################################################

// A.1. GLOBAL STYLE --------------------------------------

html, body { font-family: $font; color: $grey; }

// A.1.1. Headings ----------

h1 { 
    font-family: $font; font-weight: 600;
}

h2 { 
    font-family: $fnt-b;
}

h3 {
    font-family: $fnt-b;
}

h4, h5, h6 {
    font-family: $font;
    font-weight: 600;
}

.fnt-lt, .fnt-lt * { font-family: $fnt-lt; }
.fnt-xb, .fnt-xb * { font-family: $fnt-xb; }
.fnt-b, .fnt-b * { font-family: $fnt-b; } 
.fnt-sb, .fnt-sb *, .fnt-pm, .fnt-pm * { font-family: $fnt-sb; } 

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
