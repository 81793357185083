@use '_module-index' as *;
.mWipe {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100% !important;
    min-height: 100vh !important;
    background-color: #1c4b9d;
    z-index: 5;
    transform: translateY(100%);
}

.oComponent {
    position: relative;
}
