@use '_module-index' as *;
// COMPONENT [ NAVBAR ] ###############################################################################################

// A.1. COMPONENT STYLE -----------------------------------

.mSocialMedia {
    padding: 0;
    margin: 0 0 60px;
    list-style-type: none;
    display: flex;
    .mSocialItem {
        flex: 1 1;
        text-align: center;
        .mLink {
            display: block;
        }
    }
    &.themeLight {
        justify-items: flex-start;
        margin: 64px 0 32px;
        .mSocialItem {
            flex: none;
            margin-right: 14px;
        }
    }
    &.themeDark {
        justify-items: flex-start;
        margin: 0;
        .mSocialItem {
            flex: none;
            margin-right: 20px;
            .mLink {
                width: 30px;
                height: 30px;
            }
        }
    }
}

// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
}
@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .mSocialMedia {
        margin: 0;
        &.themeDark {
            justify-items: flex-start;
            .mSocialItem {
                flex: 1 1;
            }
        }
    }
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
