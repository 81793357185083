@use '_module-index' as *;
// COMPONENT [ NAVBAR ] ###############################################################################################

// A.0. VARIABLE IMPORTS ----------------------------------

@import '../../../../src/styles/_module-index';

// A.0. END -----------------------------------------------

// A.1. COMPONENT STYLE -----------------------------------

.oHeader {
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: white;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    &.top {
        position: fixed;
    }
    &.fixed {
        position: fixed;
        .oHeaderWrapper {
            padding: 10px 15px;
        }

        .mSiteLogo {
            width: 60px;
            transition: 0.3s;
        }
    }
}

.oContainer {
    padding: 0 !important;
    margin: 0 auto;
}

.oHeaderWrapper {
    transition: 0.3s;
    position: relative;
    padding: 12.5px 15px;
    background-color: white;
    z-index: 10;
}

.mBurgerWrapper {
    @include vertical-align(absolute);
    left: 15px;
}

.mSiteLogo {
    transition: 0.3s;
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 102px;
}

.mLogoLink {
    display: block;
}

.oNavList,
.mLangDropdown {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.oMainNav {
    padding: 80px 34px 24px;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    left: 0;
    bottom: 0;
    background-color: white;
    z-index: 5;
    .mNavBurger {
        @include center-align();
        bottom: 30px;
    }
}

.menuItemWrapper {
    display: block;
    position: relative;
}

.navOpen {
    transform: translateY(0%);
    transition: 0.3s;
}
.navClosed {
    transform: translateY(-120%);
    transition: 0.6s;
}

// A.1. END -----------------------------------------------
// A.2. NAVIGATION ----------------------------------------

.oNav {
    display: block;
    margin-top: 20px;
}

.oNavList {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    .mItem {
        padding: 8px 20px;
        border: 1px solid #ccc;
        &:first-child {
            margin-right: 15px;
        }
    }
}

// A.2. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($lg-dn) {
    .oHeader {
        &.fixed {
            .oHeaderWrapper {
                padding: 10px 15px;
                transition: 0.3s;
            }
        }
    }

    .oHeaderWrapper {
        &.isActive {
            box-shadow: inset 0px 0 30px rgb(0 0 0 / 16%);
        }
        &.notActive {
            box-shadow: none;
        }
    }
    .menuItemWrapper {
        margin: 0 0 24px 0;
        font-family: Nunito-Bold, sans-serif;
    }

    .oNavList {
        flex-direction: column;
    }
}

@media ($xs-dn) {
    .oNavList {
        margin-top: 100px;
    }
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
    .oHeader {
        position: relative;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        &.fixed {
            .oHeaderWrapper {
                padding: 0;
            }
            .oContainer,
            .oMainNav {
                align-items: center;
            }
            .mSiteLogo {
                width: 90px;
                margin: 10px 0;
            }
        }
    }

    .oContainer {
        padding: 0 24px !important;
        display: flex;
        justify-content: space-between;
    }

    .oHeaderWrapper {
        border: 0;
        padding: 0;
        display: inline-block;
        max-width: 120px;
        flex: 1 1 150px;
    }

    .mCtaWrapper {
        display: none;
        opacity: 0;
    }

    .mSiteLogo {
        margin: 18.8px 0;
        width: 100%;
        height: auto;
        max-width: 113px;
    }

    .menuItemWrapper {
        display: inline-block;
        margin-left: 30px;
    }

    .oMainNav {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: right;
        min-height: auto;
    }

    .navOpen {
        transform: none;
        transition: 0s;
    }
    .navClosed {
        transform: none;
        transition: 0s;
    }

    .oNavList {
        display: inline-block;
    }
}

// 1200px up
@media ($xl-up) {
    .oContainer {
        padding: 0 30px !important;
    }
    .mLangBtn {
        padding: 27.5px 0;
    }
}

// 1400px up
@media ($xxl-up) {
}

// 1920px up
@media ($hd-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
