// GLOBAL [ TYPOGRAPHY ]  #############################################################################################

// A.1. FONT SOURCE ---------------------------------------

// A.1.1. NUNITO ------------
@font-face {
    font-family: Nunito-Light;
    src: url('../../fonts/Nunito/Nunito-Light.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-Light.woff') format('woff');
}
@font-face {
    font-family: Nunito-Regular;
    src: url('../../fonts/Nunito/Nunito-Regular.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-Regular.woff') format('woff');
}

@font-face {
    font-family: Nunito-Bold;
    src: url('../../fonts/Nunito/Nunito-Bold.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-Bold.woff') format('woff');
}

@font-face {
    font-family: Nunito-SemiBold;
    src: url('../../fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-SemiBold.woff') format('woff');
}

@font-face {
    font-family: Nunito-ExtraBold;
    src: url('../../fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype');
    src: url('../../fonts/Nunito/Nunito-ExtraBold.woff') format('woff');
}

// A.1.2. QUICKSAND ---------
@font-face {
    font-family: Quicksand-Bold;
    src: url('../../fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
    src: url('../../fonts/Quicksand/Quicksand-Bold.woff') format('woff');
}
@font-face {
    font-family: Quicksand-Medium;
    src: url('../../fonts/Quicksand/Quicksand-Medium.ttf') format('truetype');
    src: url('../../fonts/Quicksand/Quicksand-Medium.woff') format('woff');
}
@font-face {
    font-family: Quicksand-Regular;
    src: url('../../fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
    src: url('../../fonts/Quicksand/Quicksand-Regular.woff') format('woff');
}

$fnt-lt:    Nunito-Light, sans-serif;
$font:      Nunito-Regular, sans-serif;
$fnt-b:     Nunito-Bold, sans-serif; 
$fnt-sb:    Nunito-SemiBold, sans-serif;
$fnt-xb:    Nunito-ExtraBold, sans-serif;

$qs-bold:   Quicksand-Bold, sans-serif;
$qs-med:   Quicksand-Medium, sans-serif;
$quicksand:   Quicksand-Regular, sans-serif;

// A.1. END -----------------------------------------------

// A.2. RESPONSIVE FONT VARIABLES -------------------------
// font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));

$view-min-xs: 320;
$view-max-md: 1400;

// A.2. END -----------------------------------------------

// A.3. TYPOGRAPHY STYING ---------------------------------
// A.3.1. QUICK RESPONSIVE FONTS --------------------------

// H1 -----------------------
$h1-min-xs: 28; // minimum font size at smallest screen size
$h1-max-md: 46; // maximum font size at largest screen size
$h1-max: 46; // failsafe for larger screens

$h1-minLH-xs: 32; // minimum line height at smallest screen size
$h1-maxLH-md: 52; // maximum line height at largest screen size
$h1-maxLH: 52; // failsafe for larger screens

h1, .fntH1, .fnt42, .fnt42 * {
    font-size: calc(
        #{$h1-min-xs}px + (#{$h1-max-md} - #{$h1-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$h1-minLH-xs}px + (#{$h1-maxLH-md} - #{$h1-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: H1 ------------------

// H1 -----------------------
$h1s-min-xs: 26; // minimum font size at smallest screen size
$h1s-max-md: 38; // maximum font size at largest screen size
$h1s-max: 38; // failsafe for larger screens

$h1s-minLH-xs: 32; // minimum line height at smallest screen size
$h1s-maxLH-md: 48; // maximum line height at largest screen size
$h1s-maxLH: 48; // failsafe for larger screens

.fntH1s, .fntH1s * {
    font-size: calc(
        #{$h1s-min-xs}px + (#{$h1s-max-md} - #{$h1s-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$h1s-minLH-xs}px + (#{$h1s-maxLH-md} - #{$h1s-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: H1 ------------------

// H2 -----------------------
$h2-min-xs: 26;
$h2-max-md: 38;
$h2-max: 38;

$h2-minLH-xs: 32;
$h2-maxLH-md: 48;
$h2-maxLH: 48;

h2,
.fntH2 {
    font-size: calc(
        #{$h2-min-xs}px + (#{$h2-max-md} - #{$h2-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$h2-minLH-xs}px + (#{$h2-maxLH-md} - #{$h2-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: H2 ------------------

// H4 -----------------------
$h4-min-xs: 20;
$h4-max-md: 24;
$h4-max: 24;

$h4-minLH-xs: 28;
$h4-maxLH-md: 28;
$h4-maxLH: 28;

h4,
.fntH4 {
    font-size: calc(
        #{$h4-min-xs}px + (#{$h4-max-md} - #{$h4-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$h4-minLH-xs}px + (#{$h4-maxLH-md} - #{$h4-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: H2 ------------------

// 46px - 28px --------------
$f46-min-xs: 28;
$f46-max-md: 46;
$f46-max: 46;

$f46-minLH-xs: 32;
$f46-maxLH-md: 52;
$f46-maxLH: 52;

.fnt46,
.fnt46 * {
    font-size: calc(
        #{$f46-min-xs}px + (#{$f46-max-md} - #{$f46-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f46-minLH-xs}px + (#{$f46-maxLH-md} - #{$f46-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs})) 
    );
}
// END: 46px - 32px ---------

// 30px fixed ---------------
.fnt38f,
.fnt38f * {
    font-size: 38px;
    line-height: 48px;  
}
// END: 30px fixed ----------

// 38px - 30px --------------
$f34-min-xs: 30;
$f34-max-md: 38;
$f34-max: 38;

$f34-minLH-xs: 34;
$f34-maxLH-md: 42;
$f34-maxLH: 42;

.fnt34,
.fnt34 * {
    font-size: calc(
        #{$f34-min-xs}px + (#{$f34-max-md} - #{$f34-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f34-minLH-xs}px + (#{$f34-maxLH-md} - #{$f34-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs})) 
    );
}
// END: 38px - 30px ---------

// 30px fixed ---------------
.fnt30f,
.fnt30f * {
    font-size: 30px;
    line-height: 36px;  
}
// END: 30px fixed ----------


// 30px - 22px --------------
$f30-min-xs: 22;
$f30-max-md: 30;
$f30-max: 30;

$f30-minLH-xs: 30;
$f30-maxLH-md: 36;
$f30-maxLH: 36;

.fnt30,
.fnt30 * {
    font-size: calc(
        #{$f30-min-xs}px + (#{$f30-max-md} - #{$f30-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f30-minLH-xs}px + (#{$f30-maxLH-md} - #{$f30-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 30px - 22px ---------



// 25px fixed ---------------
.fnt25f,
.fnt25f * {
    font-size: 25px;
    line-height: normal; 
}
// END: 25px fixed ----------

// 25px fixed ---------------
.fnt24f,
.fnt24f * {
    font-size: 24px;
    line-height: 28px; 
}
// END: 25px fixed ----------

// 24px - 18px --------------
$f24l-min-xs: 18;
$f24l-max-md: 24;
$f24l-max: 24;

$f24l-minLH-xs: 24;
$f24l-maxLH-md: 28;
$f24l-maxLH: 28;

.fnt24l,
.fnt24l * {
    font-size: calc(
        #{$f24l-min-xs}px + (#{$f24l-max-md} - #{$f24l-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f24l-minLH-xs}px + (#{$f24l-maxLH-md} - #{$f24l-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 24px - 18px ---------

// 24px - 16px --------------
$f24-min-xs: 16;
$f24-max-md: 24;
$f24-max: 24;

$f24-minLH-xs: 22;
$f24-maxLH-md: 28;
$f24-maxLH: 28;

.fnt24,
.fnt24 * {
    font-size: calc(
        #{$f24-min-xs}px + (#{$f24-max-md} - #{$f24-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f24-minLH-xs}px + (#{$f24-maxLH-md} - #{$f24-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 24px - 16px ---------

// 20px - 18px --------------
$f20l-min-xs: 18;
$f20l-max-md: 20;
$f20l-max: 20;

$f20l-minLH-xs: 24;
$f20l-maxLH-md: 28;
$f20l-maxLH: 28;

.fnt20l,
.fnt20l * {
    font-size: calc(
        #{$f20l-min-xs}px + (#{$f20l-max-md} - #{$f20l-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f20l-minLH-xs}px + (#{$f20l-maxLH-md} - #{$f20l-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 24px - 16px ---------

// 18px fixed ---------------
.fnt18f,
.fnt18f * {
    font-size: 18px;
    line-height: 24px; 
}
// END: 18px fixed ----------

// 18px - 16px --------------
$f18l-min-xs: 16;
$f18l-max-md: 18;
$f18l-max: 18;

$f18l-minLH-xs: 21;
$f18l-maxLH-md: 24;
$f18l-maxLH: 24;

.fnt18l,
.fnt18l * {
    font-size: calc(
        #{$f18l-min-xs}px + (#{$f18l-max-md} - #{$f18l-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f18l-minLH-xs}px + (#{$f18l-maxLH-md} - #{$f18l-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 16px - 14px ---------

// 16px - 14px --------------
$f16s-min-xs: 14;
$f16s-max-md: 16;
$f16s-max: 16;

$f16s-minLH-xs: 16;
$f16s-maxLH-md: 20;
$f16s-maxLH: 20;

.fnt16s,
.fnt16s * {
    font-size: calc(
        #{$f16s-min-xs}px + (#{$f16s-max-md} - #{$f16s-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc(
        #{$f16s-minLH-xs}px + (#{$f16s-maxLH-md} - #{$f16s-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 16px - 14px ---------

// 16px - 24px --------------
$f16r-min-xs: 20;
$f16r-max-md: 16;
$f16r-max: 16;

$f16r-minLH-xs: 24;
$f16r-maxLH-md: 18;
$f16r-maxLH: 18;

.fnt16r,
.fnt16r * {
    font-size: calc(
        #{$f16r-min-xs}px + (#{$f16r-max-md} - #{$f16r-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc( 
        #{$f16r-minLH-xs}px + (#{$f16r-maxLH-md} - #{$f16r-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 16px - 14px ---------

// 16px - 18px --------------
$f16rs-min-xs: 18;
$f16rs-max-md: 16;
$f16rs-max: 16;

$f16rs-minLH-xs: 20;
$f16rs-maxLH-md: 18;
$f16rs-maxLH: 18;

.fnt16rs,
.fnt16rs * {
    font-size: calc(
        #{$f16rs-min-xs}px + (#{$f16rs-max-md} - #{$f16rs-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc( 
        #{$f16rs-minLH-xs}px + (#{$f16rs-maxLH-md} - #{$f16rs-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 16px - 14px ---------

// 16px fixed ---------------
.fnt16f, .fnt16f * { font-size: 16px; line-height: 20px; }
// END: 14px fixed ----------

// 14px fixed ---------------
.fnt14f, .fnt14f * { font-size: 14px; line-height: 16px; }
// END: 14px fixed ----------

// 12px fixed ---------------
.fnt13f, .fnt13f * { font-size: 13px; line-height: 16px; }
// END: 14px fixed ----------

// 12px fixed ---------------
.fnt12f, .fnt12f * { font-size: 12px; line-height: 18px; letter-spacing: 0.05em; }
// END: 14px fixed ----------

// 12px - 10px --------------
$f12-min-xs: 10;
$f12-max-md: 12;
$f12-max: 12;

$f12-minLH-xs: 12;
$f12-maxLH-md: 14;
$f12-maxLH: 14;

.fnt12,
.fnt12 * {
    font-size: calc(
        #{$f12-min-xs}px + (#{$f12-max-md} - #{$f12-min-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
    line-height: calc( 
        #{$f12-minLH-xs}px + (#{$f12-maxLH-md} - #{$f12-minLH-xs}) *
            ((100vw - #{$view-min-xs}px) / (#{$view-max-md} - #{$view-min-xs}))
    );
}
// END: 12px - 10px ---------

// A.3. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------
// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

// 575px down
@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
}

// 992px up
@media ($lg-up) {
}

// 1200px up
@media ($xl-up) {
}

// 1400px up
@media ($xxl-up) {
    h1, h1 *, .fntH1, .fntH1 *, .fnt42, .fnt42 * { font-size: #{$h1-max}px; line-height: #{$h1-maxLH}px; }
    .fntH1s, .fntH1s * { font-size: #{$h1s-max}px; line-height: #{$h1s-maxLH}px; }
    h2, .fntH2 { font-size: #{$h2-max}px; line-height: #{$h2-maxLH}px; }
    h4, .fntH4 { font-size: #{$h4-max}px; line-height: #{$h4-maxLH}px; }
    .fnt46, .fnt46 * { font-size: #{$f46-max}px; line-height: #{$f46-maxLH}px; }
    .fnt38f, .fnt38f * { font-size: 38px; line-height: 48px; }
    .fnt34, .fnt34 * { font-size: #{$f34-max}px; line-height: #{$f34-maxLH}px; }
    .fnt30f, .fnt30f * { font-size: 30px; line-height: normal; }
    .fnt25f, .fnt25f * { font-size: 25px; line-height: normal; }
    .fnt24f, .fnt24f * { font-size: 24px; line-height: 28px; }
    .fnt20l, .fnt20l * { font-size: #{$f20l-max}px; line-height: #{$f20l-maxLH}px; }
    .fnt18l, .fnt18l * { font-size: #{$f18l-max}px; line-height: #{$f18l-maxLH}px; }
    .fnt18f, .fnt18f * { font-size: 18px; line-height: 24px; }
    .fnt16f, .fnt16f * { font-size: 16px; line-height: 20px; }
    .fnt16r, .fnt16r * { font-size: #{$f16r-max}px; line-height: #{$f16r-maxLH}px; }
    .fnt16rs, .fnt16rs * { font-size: #{$f16rs-max}px; line-height: #{$f16rs-maxLH}px; }
    .fnt16s, .fnt16s * { font-size: #{$f16s-max}px; line-height: #{$f16s-maxLH}px; }
    .fnt14f, .fnt14f * { font-size: 14px; line-height: 16px; }
    .fnt13f, .fnt13f * { font-size: 13px; line-height: 16px; }
    .fnt12f, .fnt12f * { font-size: 12px; line-height: 18px; letter-spacing: 0.05em; }
}

// B.2. END --------------------------------
// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
